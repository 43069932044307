import SvgColor from 'components/svg-color';
import { PATH_DASHBOARD, PATH_PAGE } from 'routes/paths';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('blog'),
  home: icon('home'),
  football: icon('football'),
  about_us: icon('about-us'),
  contact_us: icon('contact-us'),
};

const navConfig = [
  {
    items: [
      // { title: 'Home', path: PATH_DASHBOARD?.root, icon: ICONS.home },
      { title: 'Home', path: PATH_PAGE.blogs, icon: ICONS.home },
      { title: 'Football', path: PATH_DASHBOARD?.matches?.allMatches, icon: ICONS.football },
      { title: 'Blogs', path: PATH_PAGE?.blogs, icon: ICONS.blog },
      { title: 'About Us', path: PATH_PAGE?.about, icon: ICONS.about_us },
      { title: 'Contact Us', path: PATH_PAGE?.contact, icon: ICONS.contact_us },
    ],
  },
];

export default navConfig;
